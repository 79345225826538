<template>
  <div class="product-chart">
    <PageTitle :label="$t('product_dashboard_main.title_scatter_plot')" :iconPath="iconPath" />
    <PageFilter :isGetAllProducts="isShowBubbleDisableSelected" @update="updateSelectedChart" :productList="productList" @selectProductsMaster="handleSelectProductsMaster" :productsMasterListDefault="productsMasterList"/>
    <div class="tabs-header">
      <TabHeader :tab="selectedTab" @update:tab="handleUpdateTab" />
    </div>
    <div class="tab-year-select" v-if="selectedTab === 0">
      <v-select
        solo
        flat
        :items="getYearList"
        v-model="curentYear"
        @change="handleGetDataChartUpdatedByYear(curentYear)"
        :label='$t("dashboard_main.placeholder_select_item_label")'
        item-text="value"
        item-value="value"
        class="select-type select-default select-types list-menu--select select-item durations-select-item  mt-0 mb-12"
        :menu-props="{ contentClass: 'select-menu product-select' }"
      />
    </div>
    <div v-else class="filter-durations">
      <FilterDuration :startMonthProps="startMonthProps" :durationsSelectedData="durationsSelectedData" @filterUpdated="handleGetDataChartUpdated" :tab="selectedTab" />
    </div>
    <div class="tabs-content">
      <chart-bubble
        :selectedTab="selectedTab"
        :branchIds="branchIds"
        @updateSelectedData="handleUpdateSelectedData"
        :productsMasterList="getListProductId"
        :isGetAllProducts="isShowBubbleDisableSelected"
        :isHasBranchNull="isHasBranchNull"
        :filterUpdatedKeys="filterUpdatedKeys"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ChartBubble from '@/components/products/charts/period/bubble-chart/chart-data.vue';
import PageTitle from '@/components/products/common/page-title.vue';
import PageFilter from '@/components/products/common/page-filter.vue';
import { PRODUCT_DASHBOARD } from '@/constants/products/define-data';
import FilterDuration from '@/components/products/charts/filter-duration/index.vue';
import TabHeader from '@/components/products/common/tab-header.vue';
export default {
  components: { PageTitle, PageFilter, ChartBubble, FilterDuration, TabHeader },
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    productList: {
      type:  Array,
      default: () => [],
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    },
    startMonthProps: {
      type: Number,
      default: 0,
    },
    yearList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTab: 0,
      iconPath: 'dashboard/icon-ghg-bubble.svg',
      productsMasterList: [],
      isShowBubbleDisableSelected: true,
      filterUpdatedKeys: 0,
      durationsSelectedData: {},
      curentYear: null,
    };
  },
  computed: {
    ...mapState('product', ['dashboard']),
    getYearList() {
      let yearList = [ ...this.yearList ]
      if(this.startMonthProps > 1) {
        yearList.shift()
      }
      return yearList
    },
    getListProductId() {
      const realProductList = this.productList.filter((item) => item.id);
      const mapProductListData = (list) => 
        list.map((item) => (item.id));

      if (this.productsMasterList.length > 0) { // has selected products 

        // filter products selected matching pulldown current
        const realProductListSelected = realProductList.filter((item) =>
          this.productsMasterList.includes(item.id)
        );

        if (realProductListSelected.length > 0) {
          return mapProductListData(realProductListSelected);
        }
      }
      return mapProductListData(realProductList); // don't selected products
    },
  },
  mounted() {
  },
  created() {
    this.selectedTab = this.dashboard.emissionsPerProducts.selectedType;
    this.productsMasterList = this.dashboard.emissionsPerProducts.productMasterIds;
    this.isShowBubbleDisableSelected = this.dashboard.emissionsPerProducts.isGetAllProduct === false ? false : true;
    this.updateSelectedDurationStore();
    this.selectedFirstItem();
  },
  methods: {
    ...mapActions('product', ['updateEmissionsPerProducts']),
    handleUpdateTab(newTab) {
      this.selectedTab = newTab;
      this.updateDataToStore();
      this.selectedFirstItem();
    },
    selectedFirstItem() {
      let index = -1;
      if (this.selectedTab === 0) {
        const oldYearSelected = this.durationsSelectedData?.selectedYear;
        index = this.getYearList.findIndex((item) => item?.value === oldYearSelected?.year);
        if (index < 0) {
          index = 0; // current FY
        }
        this.curentYear = this.getYearList[index]?.value;
      }
      this.updateDataToStore();
    },
    updateSelectedDurationStore() {
      this.durationsSelectedData = {
        selectedYear: this.dashboard.emissionsPerProducts?.selectedData?.selectedYear,
        selectedHalfYear: this.dashboard.emissionsPerProducts?.selectedData?.selectedHalfYear,
        selectedQuarterYear: this.dashboard.emissionsPerProducts?.selectedData?.selectedQuarterYear,
        selectedMonthYear: this.dashboard.emissionsPerProducts?.selectedData?.selectedMonthYear,
      }
    },
    handleGetDataChartUpdatedByYear(year) {
      this.updateEmissionsPerProducts({
        ...this.dashboard.emissionsPerProducts,
        selectedData: {
          ...this.dashboard.emissionsPerProducts.selectedData,
          selectedYear: {year: year},
        },
      });
      this.filterUpdatedKeys ++;
    },
    handleGetDataChartUpdated(store) {
      this.updateEmissionsPerProducts({
        ...this.dashboard.emissionsPerProducts,
        selectedData: {
          ...this.dashboard.emissionsPerProducts.selectedData,
          ...store
        },
      });
      this.filterUpdatedKeys ++;
    },
    handleSelectProductsMaster(value) {
      this.productsMasterList = value;
      this.updateDataToStore();
    },
    updateDataToStore() {
      if(this.isShowBubbleDisableSelected) {
        this.productsMasterList = [];
      }
      const selectedData = this.dashboard.emissionsPerProducts.selectedData;
      this.updateEmissionsPerProducts({
        isGetAllProduct: this.isShowBubbleDisableSelected,
        productMasterIds: this.productsMasterList,
        selectedType: this.selectedTab,
        selectedData: {
          ...this.dashboard.emissionsPerProducts.selectedData,
          duration_type_month_id: this.currentDurationMonthId,
          month: this.month || selectedData.month,
          yearTypeYear:  this.selectedTab === 0 ? this.yearTypeYear : selectedData.yearTypeYear,
          yearTypeMonth:  this.selectedTab === 0 ? selectedData.yearTypeMonth : this.yearTypeMonth,
          selectedYear: {year: this.curentYear},
        }
      });
    },
    updateSelectedChart(value) {
      this.isShowBubbleDisableSelected = PRODUCT_DASHBOARD.GET_ALL_PRODUCT === value;
    },
    handleUpdateSelectedData(value) {
      this.selectedTab = value.selectedTab;
      if(this.selectedTab > 0) {
        this.month = value.month;
      }
      if(this.selectedTab === 0) {
        this.yearTypeYear = value.year
      } else {
        this.yearTypeMonth = value.year
      }
    },
  },
  watch: {
    isShowBubbleDisableSelected: {
      handler() {
        this.updateDataToStore();
      },
      deep: true
    },
    selectedTab: {
      handler(newVal) {
        this.updateSelectedDurationStore();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.product-chart {
  width: 100%;

  .tabs-header {
    margin-bottom: 16px;
  }

  .tabs-content {
    .theme--light.v-tabs-items {
      background-color: transparent;
    }
  }

  .product-filter_width {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 48px;

    .product-filter {
      width: 288px;

      @include desktop {
        width: 240px;
      }
    }
  }
}

.period-combobox {
  width: 100%;
  margin-bottom: 48px;
}
.tab-year-select {
  width: 100%;
  @include desktop {
    width: 100px;
  }
}
@include desktop {
  .product-chart {
    .tabs-content {
      margin-top: -38px;
    }
  }
}
</style>